import React from 'react';
import logo from '../Assets/logo.jpg';
import { Link, useLocation } from 'react-router-dom';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const Navbar = () => {
	const location = useLocation();

	useGSAP(() => {
		gsap.fromTo(
			'.lights',
			{
				backgroundSize: '100% 80%',
			},
			{
				backgroundSize: '100% 110%',
				yoyo: true,
				yoyoEase: 'sine',
				repeat: -1,
				duration: 10,
			}
		);
	});

	return (
		<header className="sticky top-0 z-20 bg-black lg:flex justify-between items-center">
			<nav
				className="p-3 h-18 flex items-center lights bg-grad-custom"
				style={{
					backgroundPositionY: '-35px',
					backgroundRepeat: 'no-repeat',
				}}
			>
				<Link to="/">
					<img src={logo} alt="" className="h-10 relative -z-10" />
				</Link>
			</nav>
			<div className="overflow-y-auto max-w-full">
				<div className="flex gap-3 p-3 pt-2 px-5 whitespace-nowrap">
					<Link
						to="/"
						className="hover:bg-white/5 px-3 py-1 rounded-full "
						style={{
							color: location.pathname === '/' ? '#ef4444' : '',
						}}
					>
						Home
					</Link>
					<Link
						to="/universe"
						className=" hover:bg-white/5 px-3 py-1 rounded-full "
						style={{
							color:
								location.pathname === '/universe'
									? '#ef4444'
									: '',
						}}
					>
						360 Universe
					</Link>
					<Link
						to="/interactive"
						className="hover:bg-white/5 px-3 py-1 rounded-full "
						style={{
							color:
								location.pathname === '/interactive'
									? '#ef4444'
									: '',
						}}
					>
						Interactive Cinema
					</Link>
					<Link
						to="/about"
						className="hover:bg-white/5 px-3 py-1 rounded-full "
						style={{
							color:
								location.pathname === '/about' ? '#ef4444' : '',
						}}
					>
						About Us
					</Link>
				</div>
			</div>
			<div className="hidden lg:block text-right">
				<a
					href={`whatsapp://send?text=${window.location.href}`}
					className="flex items-center gap-2 bg-white/5 p-2 rounded-md cursor-pointer z-10"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="w-4 h-4"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
						/>
					</svg>
					Share
				</a>
			</div>
		</header>
	);
};

export default Navbar;
