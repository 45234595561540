import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const VideoActions = ({ pointer, playNext=()=>{}, show=false }) => {
	/* 
	const showActionAnimation = ()=>{
		return gsap.fromTo('.video-actions', {
			opacity: 0
		}, {
			opacity: 1,
			duration: 1
		})
	}
	
	*/
	const hideActionAnimation = ()=>{
		return gsap.fromTo('.video-actions', {
			opacity: 1
		}, {
			opacity: 0,
			duration: 0.7
		})
	}

	useGSAP(()=>{
		if(pointer.context.length !== 0)
			gsap.fromTo('.video-actions', {
				opacity: 0
			}, {
				opacity: 1,
				duration: 0.4
			})
	}, []); 

	const onClick = (b)=>{
		hideActionAnimation().then(()=>{
			playNext(b.linkedNode);
		})
	}

	return (
		<div className="video-actions absolute top-0 left-0 right-0 bottom-0 bg-black/80 duration-500 opacity-0 z-50">
			<div className="h-full w-full max-w-xl m-auto flex flex-col justify-end pb-7">
				<div className="p-5">
					<h1
						className="text-center p-5 font-semibold"
						style={{ fontSize: '1em' }}
					>
						{pointer.context}
					</h1>
					<div className="flex flex-wrap gap-2 items-stretch justify-center text-xs">
						{pointer.buttons.length > 0 &&
							pointer.buttons.map((b,i) => (
								<button
									key={i}
									className="min-w-38 bg-white/10 border-2 border-white/50 hover:border-white py-2 px-2 rounded-md flex-grow max-w-40 min-h-6 text-pretty hover:bg-white/20 transition-all duration-300"
									style={pointer.defaultFlow===i?{
										animation: 'opacity-low 0.6s alternate infinite',
                                        background: '#ffffff30',
                                        borderColor: '#ffffff'
									}:{}}

									onClick={()=>onClick(b)}
								>
									{b.title}
								</button>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default VideoActions;
