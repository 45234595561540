import React, { useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

import Ayan from '../Assets/Ayan.jpeg';
import Prantik from '../Assets/Prantik.jpeg';
import Dipti from '../Assets/Dipti.jpeg';
import Stacy from '../Assets/Stacy.jpg';

const About = () => {
	useEffect(() => {
		document.querySelector('title').innerText = 'Enteracty | About';
	}, []);
	return (
		<div>
			<Navbar></Navbar>
			<div className="p-5 md:py-10 md:px-[8vw] lg:px-[15vw] font-sm flex flex-col gap-5">
				<div className="font-semibold text-xl text-center lg:pt-5">
					About Enteracty
				</div>
				<div className="text-white/80 text-center">
					Welcome to Enteracty, the home of immersive storytelling
					where your choices shape the narrative. We believe in the
					power of interactive experiences, transforming passive
					viewing into active participation. At Enteracty, every story
					is a journey, and every decision you make influences the
					path ahead.
				</div>
				<div className="font-semibold text-xl text-center lg:pt-5">
					Our Vision
				</div>
				<div className="text-white/80 text-center">
					Enteracty was born from a vision to redefine entertainment.
					We strive to break away from the conventional and invite you
					into a world where storytelling is a dynamic, personal
					experience. Our platform empowers you to be the author of
					your own adventure, making each story uniquely yours.
				</div>
				<div className="font-semibold text-xl text-center lg:pt-5">
					Our Team
				</div>
				<div className="text-white/80 text-center">
					Enteracty is fueled by a passionate team dedicated to
					pushing the boundaries of entertainment. From storytellers
					and developers to creatives and visionaries, we come
					together to craft a platform that puts you at the
					center of the story.
				</div>
				<div className="py-10 flex justify-center flex-wrap items-center gap-10">
					<div className="flex flex-col items-start gap-4">
						<img
							src={Prantik}
							alt=""
							className="w-[8vw] max-w-[200px] min-w-[100px] aspect-square rounded-3xl"
						/>
						<div className="flex flex-col gap-1">
							<div className="font-semibold pb-1">
								Prantik Das
							</div>
							<div className="text-white/70 text-xs">
								Co Founder, CEO
							</div>
							<div className='flex gap-1 items-center text-white/80'>
								<a
									href="https://www.linkedin.com/in/prantik-das-/"
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="13"
										height="13"
										fill="currentColor"
										class="bi bi-linkedin"
										viewBox="0 0 16 16"
									>
										<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
									</svg>
								</a>
								<a
									href="mailto:pprantikdas@gmail.com"
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-envelope-fill"
										viewBox="0 0 16 16"
									>
										<path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
									</svg>
								</a>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-start gap-4">
						<img
							src={Dipti}
							alt=""
							className="w-[8vw] max-w-[200px] min-w-[100px] aspect-square rounded-3xl"
						/>
						<div className="flex flex-col gap-1">
							<div className="font-semibold pb-1">
								Dipti Karmakar
							</div>
							<div className="text-white/70 text-xs">
								Co Founder, COO
							</div>
							<div className='flex gap-1 items-center text-white/80'>
								<a
									href="https://www.linkedin.com/in/dipti-karmakar-37a84917a/"
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="13"
										height="13"
										fill="currentColor"
										class="bi bi-linkedin"
										viewBox="0 0 16 16"
									>
										<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
									</svg>
								</a>
								<a
									href="mailto:diptikarmakar0016@gmail.com"
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-envelope-fill"
										viewBox="0 0 16 16"
									>
										<path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
									</svg>
								</a>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-start gap-4">
						<img
							src={Ayan}
							alt=""
							className="w-[8vw] max-w-[200px] min-w-[100px] aspect-square rounded-3xl"
						/>
						<div className="flex flex-col gap-1">
							<div className="font-semibold pb-1">
								Ayan Das
							</div>
							<div className="text-white/70 text-xs">
								Co Founder, CTO
							</div>
							<div className='flex gap-1 items-center text-white/80'>
								<a
									href="https://www.linkedin.com/in/ay4n-das/"
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="13"
										height="13"
										fill="currentColor"
										class="bi bi-linkedin"
										viewBox="0 0 16 16"
									>
										<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
									</svg>
								</a>
								<a
									href="mailto:ay4n.das@gmail.com"
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-envelope-fill"
										viewBox="0 0 16 16"
									>
										<path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
									</svg>
								</a>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-start gap-4">
						<img
							src={Stacy}
							alt=""
							className="w-[8vw] object-cover max-w-[200px] min-w-[100px] aspect-square rounded-3xl"
						/>
						<div className="flex flex-col gap-1">
							<div className="font-semibold pb-1">
							Stacy Gomes
							</div>
							<div className="text-white/70 text-xs">
								Co Founder, CCO
							</div>
							<div className='flex gap-1 items-center text-white/80'>
								<a
									href="https://www.linkedin.com/in/stacy-gomes-5116661b7/"
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="13"
										height="13"
										fill="currentColor"
										class="bi bi-linkedin"
										viewBox="0 0 16 16"
									>
										<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
									</svg>
								</a>
								<a
									href="mailto:stacyiona.g@gmail.com"
									target="_blank"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-envelope-fill"
										viewBox="0 0 16 16"
									>
										<path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
									</svg>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default About;
