import { useEffect, useRef, useState } from 'react';
import image from '../Assets/360Cropped.jpg';
import gif from '../Assets/thor.gif';
import endless from '../Assets/endless.gif';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/all';
import { Link } from 'react-router-dom';

const Home = () => {
	const imageRef = useRef(null);

	useEffect(() => {
		document.body.style.overflowY = 'hidden';
		document.querySelector('title').innerText = 'Enteracty | Home';
		document.querySelector('meta[name="description"]').content =
			"Enteracty, an interactive and immersive storytelling platform where you can choose your own adventure and explore different  branching narratives and make decisions that shape the course of your unique stories. Enteracty offers users 360 degree cinematic experiences that redefine the way stories unfold. We are equipped with futuristic Virtual Reality cinema and virtual reality games. Enteracty is more than a platform; it's an interactive, immersive film destination paving the way for the next era in entertainment and media. Join us at Enteracty, where the future of storytelling is in your hands with narrative films and gaming and user driven experience.";
		setTimeout(() => window.scrollTo(0, 0), 1000);
	}, []);
	const loadingAnimationEnded = () => {
		document.body.style.overflowY = 'auto';
		gsap.registerPlugin(ScrollTrigger);

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.backdrop-image',
				pin: false,
				pinSpacer: false,
			},
		});
		tl.to('.hotspot1', {
			top: '20%',
			scrollTrigger: {
				scrub: 2,
				start: 'top top',
				end: '+=200',
			},
		});
		tl.to('.keep-scrolling', {
			y: 30,
			scrollTrigger: {
				scrub: 2,
				start: 'top top',
				end: '+=100',
			},
		});
		tl.to('.keep-scrolling-text', {
			opacity: 0,
			scrollTrigger: {
				scrub: 2,
				start: 'top top',
				end: '+=100',
			},
		});
		tl.to('.backdrop-image', {
			backgroundPositionX: -150,
			duration: 10,
			scrollTrigger: {
				scrub: 2,
				start: 'top top',
				end: '+=2000',
			},
		});
		tl.to('.hotspot1', {
			rotateY: -90,
			scale: 0.6,
			background: '#ffffff17',
			scrollTrigger: {
				scrub: 2,
				start: 'top top',
				end: '+=360',
			},
		});
		tl.to('.infoButton', {
			opacity: 1,
			scale: 1.2,
			scrollTrigger: {
				scrub: 2,
				start: 'top top',
				end: '+=40',
			},
		});
		tl.to('.hotspot1', {
			x: '100vw',
			scrollTrigger: {
				scrub: 0.3,
				start: 'top top',
				end: '+=800',
			},
		});
		tl.fromTo(
			'.hotspot2',
			{
				y: 40,
				opacity: 0,
				scale: 0,
			},
			{
				y: 0,
				scale: 0.9,
				opacity: 1,
				scrollTrigger: {
					scrub: 0.3,
					start: '+=80',
					end: '+=80',
				},
			}
		);
		tl.from(
			'.hotspot2-text',
			{
				x: -30,
				y: 0,
				opacity: 0,
				scrollTrigger: {
					scrub: 2,
					start: '+=160',
					end: '+=40',
				},
			},
			2
		);
		tl.fromTo(
			'.hotspot2',
			{
				scale: 0.9,
			},
			{
				top: '20%',
				backgroundColor: '#ffffff17',
				scale: 0.5,
				scrollTrigger: {
					scrub: 2,
					start: '+=240',
					end: '+=40',
				},
			}
		);
		tl.to('.hotspot2', {
			x: '90vw',
			rotateY: -90,
			scrollTrigger: {
				scrub: 2,
				start: '+=240',
				end: '+=280',
			},
		});
		tl.fromTo(
			'.hotspot3',
			{
				x: '100vw',
				opacity: 0,
				rotateY: 40,
			},
			{
				x: 0,
				rotateY: 0,
				opacity: 1,
				scrollTrigger: {
					scrub: 2,
					start: '+=240',
					end: '+=240',
				},
			}
		);
		tl.to('.stagger-one', {
			color: '#ffffff',
			stagger: 0.3,
			scrollTrigger: {
				scrub: 2,
				start: '+=280',
				end: '+=240',
			},
		});
		tl.to('.hotspot3', {
			y: '-130%',
			scrollTrigger: {
				scrub: 2,
				start: '+=440',
				end: '+=960',
			},
		});
		tl.from('.hotspot4', {
			opacity: 0,
			y: 30,
			scrollTrigger: {
				scrub: 2,
				start: '+=440',
				end: '+=40',
			},
		});
		tl.from('.stagger-two', {
			color: '#ffffff00',
			x: 50,
			stagger: 0.2,
			scrollTrigger: {
				scrub: 2,
				start: '+=480',
				end: '+=120',
			},
		});
		tl.fromTo(
			'.go-for-head',
			{
				y: -20,
			},
			{
				y: 20,
				scrollTrigger: {
					scrub: 2,
					start: '+=480',
					end: '+=720',
				},
			}
		);
		tl.fromTo(
			'.go-for-chest',
			{
				y: -20,
			},
			{
				y: 20,
				scrollTrigger: {
					scrub: 2,
					start: '+=480',
					end: '+=720',
				},
			}
		);
		tl.from('.hotspot6', {
			opacity: 0,
			y: 30,
			scrollTrigger: {
				scrub: 2,
				start: '+=640',
				end: '+=40',
			},
		});
		tl.from('.stagger-three', {
			color: '#ffffff00',
			x: -50,
			stagger: 0.2,
			scrollTrigger: {
				scrub: 2,
				start: '+=680',
				end: '+=80',
			},
		});
		tl.from('.image-endless', {
			opacity: 0,
			x: 50,
			scrollTrigger: {
				scrub: 2,
				start: '+=720',
				end: '+=80',
			},
		});
		tl.from('.hotspot7', {
			opacity: 0,
			y: 30,
			scrollTrigger: {
				scrub: 2,
				start: '+=760',
				end: '+=40',
			},
		});
		tl.fromTo(
			'.hotspot8',
			{
				opacity: 0,
				xPercent: 0,
				y: 50,
				rotateY: 0,
			},
			{
				xPercent: 0,
				y: 0,
				opacity: 1,
				scrollTrigger: {
					scrub: 2,
					start: '+=960',
					end: '+=40',
				},
			}
		);
		tl.from('.iframe', {
			opacity: 0,
			y: -100,
			scale: 0,
			borderRadius: '50%',
			scrollTrigger: {
				scrub: 2,
				start: '+=1000',
				end: '+=40',
			},
		});
		tl.fromTo(
			'.hotspot8',
			{
				xPercent: 0,
			},
			{
				rotateY: -90,
				xPercent: 80,
				scrollTrigger: {
					scrub: 2,
					start: '+=1080',
					end: '+=280',
				},
			}
		);
		tl.fromTo(
			'.hotspot9',
			{
				x: '-100vw',
			},
			{
				x: '100vw',
				scrollTrigger: {
					scrub: 2,
					start: '+=1080',
					end: '+=560',
				},
			}
		);
		tl.from('.stagger-four', {
			color: '#ffffff00',
			stagger: 0.2,
			scrollTrigger: {
				scrub: 2,
				start: '+=1160',
				end: '+=80',
			},
		});
		tl.fromTo(
			'.hotspot10',
			{
				opacity: 0,
				x: -100,
				rotateY: 40,
			},
			{
				opacity: 1,
				x: 0,
				rotateY: 0,
				scrollTrigger: {
					scrub: 2,
					start: '+=1400',
					end: '+=240',
				},
			}
		);
		tl.from('.play-button', {
			rotate: 360,
			scale: 0.5,
			scrollTrigger: {
				scrub: 2,
				start: '+=1400',
				end: '+=240',
			},
		});
		tl.from('.option-left', {
			x: -50,
			scrollTrigger: {
				scrub: 2,
				start: '+=1640',
				end: '+=240',
			},
		});
		tl.from('.option-right', {
			x: 50,
			scrollTrigger: {
				scrub: 2,
				start: '+=1640',
				end: '+=240',
			},
		});
		tl.to('.keep-scrolling', {
			y: 50,
			opacity: 0,
			scrollTrigger: {
				scrub: 2,
				start: '+=1400',
				end: '+=20',
			},
		});
	};
	useGSAP(() => {
		const tl = gsap.timeline();
		tl.from(
			'.welcome',
			{
				y: 20,
				opacity: 0,
			},
			1
		);
		tl.fromTo(
			'.red',
			{
				y: 20,
				x: 16,
				opacity: 0,
			},
			{
				y: 0,
				x: 16,
				opacity: 100,
			},
			2
		);
		tl.to(
			'.red',
			{
				x: 0,
			},
			3
		);
		tl.from(
			'.white',
			{
				x: -20,
				opacity: 0,
			},
			3
		);
		tl.to(
			'.enteracty',
			{
				x: -10,
			},
			3
		);
		tl.to(
			'.atoe',
			{
				rotateX: -450,
				opacity: 0,
				transition: {
					duration: 3,
				},
			},
			3
		);
		tl.from(
			'.etoa',
			{
				rotateX: -450,
				opacity: 0,
				transition: {
					duration: 3,
				},
			},
			3
		);
		tl.to(
			'.white-era',
			{
				color: '#ffffff',
				transition: {
					duration: 3,
				},
			},
			3
		);
		tl.to(
			'.backdrop-image',
			{
				opacity: 0.3,
				duration: 3,
				ease: 'sine.inOut',
			},
			4
		);
		tl.fromTo(
			'.backdrop-image',
			{
				backgroundPositionX: 45,
			},
			{
				backgroundPositionX: 60,
				duration: 3,
			},
			4
		);
		tl.from(
			'.drawer-button',
			{
				y: -40,
				opacity: 0,
				duration: 2,
			},
			5
		);
		tl.from(
			'.keep-scrolling',
			{
				y: 40,
				opacity: 0,
				duration: 1,
			},
			6
		);
		tl.eventCallback('onComplete', () => {
			loadingAnimationEnded();
		});
	});

	const [menuOpen, setMenuOpen] = useState(false);

	const onMenuClick = () => {
		setMenuOpen((s) => !s);
	};

	return (
		<div
			className="App bg-white text-white h-screen w-[100vw]"
			style={{ userSelect: 'none' }}
		>
			<button
				className={`menu-button fixed z-30 top-5 right-5 drawer-button flex flex-col gap-[10px] ${
					menuOpen ? 'open' : ''
				}`}
				onClick={onMenuClick}
			>
				<div className="ham ham1 h-1 w-10 rounded-full bg-white origin-left"></div>
				<div className="ham ham2 h-1 w-10 rounded-full bg-white origin-left"></div>
				<div className="ham ham3 h-1 w-10 rounded-full bg-white origin-left"></div>
			</button>
			{menuOpen && (
				<div className="backdrop fixed top-0 left-0 right-0 bottom-0 bg-black z-20 flex flex-col justify-center items-center gap-[6vh] font-medium">
					<a
						href="https://www.nopcommerce.com/images/thumbs/0005720_coming-soon-page.jpeg"
						rel="noreferrer"
						className="text-xl text-red-500 hover:text-red-500 duration-200"
					>
						Home
					</a>
					<Link
						to="/universe"
						rel="noreferrer"
						className="text-xl hover:text-red-500 duration-200"
					>
						360 Universe
					</Link>
					<Link
						to="/interactive"
						rel="noreferrer"
						className="text-xl hover:text-red-500 duration-200"
					>
						Interactive Cinema
					</Link>
					<Link
						to="/about"
						rel="noreferrer"
						className="text-xl hover:text-red-500 duration-200"
					>
						About Us
					</Link>
					<div className='flex flex-col gap-4 mt-10 items-center'>
					<span>Follow us on</span>
					<div className='flex gap-4 items-center'>
					<Link
						to="https://www.facebook.com/profile.php?id=61556365047669"
						target='_blank'
						rel="noreferrer"
						className="text-2xl hover:text-red-500 duration-200"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							className='w-7 h-7'
							viewBox="0 0 16 16"
						>
							<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
						</svg>
					</Link>
					<Link
						to="https://www.instagram.com/enteracty_social/"
						target='_blank'
						rel="noreferrer"
						className="text-2xl hover:text-red-500 duration-200"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							className='w-7 h-7'
							viewBox="0 0 16 16"
						>
							<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
						</svg>
					</Link>
					<Link
						to="https://youtube.com/@Enteracty?si=WwAB2gb-3UQkX3E2 "
						target='_blank'
						rel="noreferrer"
						className="text-2xl hover:text-red-500 duration-200"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							className='w-9 h-9'
							viewBox="0 0 16 16"
						>
							<path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
						</svg>
					</Link>
					</div>
					</div>
				</div>
			)}
			<div
				ref={imageRef}
				className="fixed backdrop-image h-screen w-screen opacity-0"
				style={{
					backgroundSize: 'cover',
					backgroundImage: `url(${image})`,
				}}
			></div>
			<div
				className="fixed top-0 left-0 w-screen h-screen"
				style={{ perspective: '380px' }}
			>
				<div className="keep-scrolling absolute bottom-16 left-1/2 -translate-x-1/2 transform-gpu gap-3 flex flex-col items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="currentColor"
						className="w-6 h-6 animate-bounce"
					>
						<path
							fillRule="evenodd"
							d="M12 2.25a.75.75 0 0 1 .75.75v16.19l6.22-6.22a.75.75 0 1 1 1.06 1.06l-7.5 7.5a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 1 1 1.06-1.06l6.22 6.22V3a.75.75 0 0 1 .75-.75Z"
							clipRule="evenodd"
						/>
					</svg>
					<div className="keep-scrolling-text">Keep Scrolling</div>
				</div>
				<div className="rounded-md text-center hotspot1 p-7 bg-transparent fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
					<button className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 opacity-0 infoButton scale-75">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							className="w-8 h-8"
						>
							<path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 0 0 7.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 0 0 4.902-5.652l-1.3-1.299a1.875 1.875 0 0 0-1.325-.549H5.223Z" />
							<path
								fillRule="evenodd"
								d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 0 0 9.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 0 0 2.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3Zm3-6a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm8.25-.75a.75.75 0 0 0-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-5.25a.75.75 0 0 0-.75-.75h-3Z"
								clipRule="evenodd"
							/>
						</svg>
					</button>

					<h5 className="welcome opacity-100">Welcome To</h5>
					<h1 className="text-4xl font-semibold enteracty flex relative left-6">
						<div className="text-red-600 red flex">
							<div>
								<div className="etoa absolute top-0 right-6">
									E
								</div>
								<div className="atoe absolute top-0 right-6">
									A
								</div>
							</div>
							<div>n</div>
						</div>
						<div className="white">t</div>
						<div className="text-red-600 red white-era">era</div>
						<div className="white">cty</div>
					</h1>
				</div>

				<div
					className="hotspot2 opacity-0 rounded-md p-7 bg-transparent fixed top-1/3 left-1/2 -translate-x-1/2 flex items-center flex-col text-center gap-5"
					style={{ perspective: '500px', transform: 'scale: 0.9' }}
				>
					<button>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							className="w-8 h-8 animate-bounce"
						>
							<path
								fillRule="evenodd"
								d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
					<div className=" w-[80vw] max-w-lg lg:text-lg hotspot2-text">
						Welcome to Enteracty, the home of immersive storytelling
						where your choices shape the narrative. We believe in
						the power of interactive experiences, where every story
						is a journey, and every decision you make influences the
						path ahead.
					</div>
				</div>
				<div className="hotspot3 opacity-0 fixed top-1/2 left-[10vw] -translate-y-[18vw] flex flex-col text-[12vw] lg:text-[13vh] font-bold text-white/20 break-keep whitespace-nowrap">
					<div className="stagger-one">360 Degree</div>
					<div className="stagger-one">Immersive</div>
					<div className="stagger-one">Experiences</div>
					<div className="hotspot4 text-lg font-normal w-[80vw] whitespace-normal opacity-100 my-8 text-[#ffbfbf]">
						Immerse yourself in captivating worlds with our 360
						video technology, making you feel that you're right in
						the middle of the story, being the main character.
					</div>
					<div className="stagger-two text-right text-white">
						Interactive
					</div>
					<div className="stagger-two text-right text-white">
						Live-action
					</div>
					<div className="stagger-two text-right text-white">
						Storytelling
					</div>

					<div className="hotspot5 relative my-14 flex justify-center">
						<img src={gif} alt="" style={{ width: '80vw' }} />
						<div className="absolute -top-1 -left-1 -right-1 -bottom-1 bg-black/30 flex justify-center items-center text-sm text-white">
							Thor should
						</div>
						<button className="go-for-head text-xs absolute bg-blue-700/40 p-5 -bottom-7 -left-6 rounded-lg border text-white">
							Go for the Head
						</button>
						<button className="go-for-chest text-xs absolute bg-blue-700/40 p-5 -bottom-7 -right-6 rounded-lg border text-white">
							Go for the Chest
						</button>
					</div>

					<div className="hotspot6 text-lg font-normal w-[80vw] whitespace-normal opacity-100 my-8 text-[#ffbfbf]">
						Enteracty aims to provide users a unique and engaging
						live-action storytelling experience. Users have the
						ability to actively participate and influence the
						direction and outcomes of the narratives.
					</div>
					<div className="stagger-three text-white">Endless</div>
					<div className="stagger-three text-white">
						Possibilities
					</div>
					<img
						src={endless}
						alt=""
						className="my-8 image-endless"
						style={{ width: '80vw' }}
					/>
					<div className="hotspot7 text-lg font-normal w-[80vw] whitespace-normal opacity-100 my-8 text-[#ffbfbf]">
						With multiple story paths, every journey on Enteracty
						offers diverse outcomes and endless possibilities. Your
						adventure, your rules.
					</div>
				</div>

				<div className="hotspot9 fixed top-1/2 left-0 -translate-y-1/2 -translate-x-[100vw] flex flex-col text-[11vw] lg:text-[11vh] font-bold text-white break-keep whitespace-nowrap">
					<div className="stagger-four">Interactive</div>
					<div className="stagger-four">and</div>
					<div className="stagger-four">Immersive</div>
					<div className="stagger-four">Brand</div>
					<div className="stagger-four">Placement</div>

					<div className="hotspot9-text text-lg font-normal w-[80vw] whitespace-normal opacity-100 my-8 text-[#ffbfbf]">
						Revolutionizing brand exposure and advertising with
						Enteracty's interactive and immersive storytelling,
						seamlessly integrating products into narratives for a
						truly engaging and unforgettable audience experience.
					</div>
				</div>

				<div className="hotspot8 opacity-0 rounded-md p-7 bg-transparent fixed top-0 left-0 right-0 bottom-0 flex items-center flex-col text-center justify-center gap-5 z-10">
					<button>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							className="w-8 h-8 animate-bounce"
						>
							<path
								fillRule="evenodd"
								d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
					<iframe
						title="map"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.93980336596!2d88.3679317!3d22.506441599999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02719841c2bbc5%3A0x35120a833b8fa110!2sSome%20Like%20It%20Hot!5e0!3m2!1sen!2sin!4v1706873427513!5m2!1sen!2sin"
						className="w-[40vw] aspect-[4/3] max-w-xs rounded-xl iframe"
						allowFullScreen={true}
						loading="async"
						referrerPolicy="no-referrer-when-downgrade"
					></iframe>
				</div>

				<div className="hotspot10 opacity-0 rounded-md p-7 bg-transparent fixed top-0 left-0 right-0 bottom-0 flex items-center flex-col text-center justify-center gap-5">
					<Link className="play-button" to={'/universe'}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							className="w-20 h-20 animate-bounce"
						>
							<path
								fillRule="evenodd"
								d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
								clipRule="evenodd"
							/>
						</svg>
					</Link>
					<div className="grid grid-cols-2 gap-2">
						<Link
							to={'/universe'}
							className="option-left bg-black whitespace-nowrap animate-pulse p-2 px-5 rounded-full text-sm border"
						>
							Virtual
						</Link>
						<Link
							to={'/interactive'}
							className="option-right bg-black whitespace-nowrap animate-pulse p-2 px-5 rounded-full text-sm border"
							style={{ animationDelay: '1000ms' }}
						>
							Interactive
						</Link>
					</div>
				</div>
			</div>
			<div className="h-[2600px] bg-black pin-spacer"></div>
		</div>
	);
};

export default Home;
