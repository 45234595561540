import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import VideoActions from '../Components/VideoActions';
import gsap from 'gsap';
import { data } from '../Components/data';

const requestFullScreen = () => {
	if (document.fullscreenElement) {
		document.exitFullscreen();
	} else {
		document.querySelector('#player').requestFullscreen();
	}
};

const Player = () => {
	const videoRef = useRef();
	const playerRef = useRef();

	const [playingNodes, setPlayingNodes] = useState({
		currentNode: data.metadata.startingNode || null,
		nextNode:
			data.nodes[data.metadata.startingNode]?.pointer?.buttons[
				data.nodes[data.metadata.startingNode].pointer.defaultFlow
			]?.linkedNode || null,
	});

	const [playedNodes, setPlayedNodes] = useState([]);
	const [progress, setProgress] = useState({});
	const [showAction, setShowAction] = useState(false);
	const [selectedOption, setSelectedOption] = useState(false);
	const [ended, setEnded] = useState(false);
	const [heightWidth, setHeightWidth] = useState({ w: 0, h: 0 });
	const [videoState, setVideoState] = useState({
		playing: false,
		volume: 1,
		muted: false,
		progressInterval: 500,
	});

	const duration = useMemo(
		() => videoRef.current?.getDuration(),
		[playingNodes.currentNode, progress]
	);
	const actionAfter = useMemo(
		() =>
			duration -
			data.nodes[playingNodes.currentNode]?.pointer?.timeBefore,
		[playingNodes.currentNode, duration]
	);

	useEffect(() => {
		const ar =
			data.metadata.aspectRatio.split(':')[0] /
			data.metadata.aspectRatio.split(':')[1];
		let w = 100;
		let h = 100 / ar;

		if (h > 100) {
			w = 100 * ar;
			h = 100;
			setHeightWidth({ w: `${w}vh`, h: `${h}vh` });
		} else {
			setHeightWidth({ w: `${w}vw`, h: `${h}vw` });
		}

		gsap.to('.fullscreen-popup-container', {
			background: '#00000088',
			delay: 2,
			duration: 1
		})
		gsap.to('.fullscreen-popup', {
			scale: 1,
			delay: 2,
			duration: 0.25
		})
	}, []);

	const closePopup = ()=>{
		gsap.to('.fullscreen-popup-container', {
			background: '#00000000',
			duration: 0.5
		})
		gsap.to('.fullscreen-popup', {
			scale: 0,
			duration: 0.25
		}).then(()=>{
			gsap.to('.fullscreen-popup-container', {
				display: 'none',
				duration: 0,
			})
		})
		setVideoState({...videoState, playing: true});
	}

	useEffect(() => {
		if (progress.playedSeconds >= actionAfter) {
			if (!selectedOption) {
				setShowAction(true);
			}
		} else {
			setShowAction(false);
		}
	}, [progress, actionAfter]);

	const playNext = (id) => {
		setSelectedOption(true);
		setPlayingNodes((s) => ({ ...s, nextNode: id }));
		if (ended) playNextVideo(id);
		else if (!videoState.playing)
			setVideoState((s) => ({ ...s, playing: true }));
	};

	const playNextVideo = (
		next = playingNodes.nextNode,
		rewindPlay = false
	) => {
		if (next && data.nodes[next]) {
			setShowAction(false);
			if (!rewindPlay)
				setPlayedNodes((s) => [...s, playingNodes.currentNode]);
			setProgress({});
			setPlayingNodes({
				currentNode: next,
				nextNode:
					data.nodes[next].pointer?.buttons[
						data.nodes[next].pointer.defaultFlow
					]?.linkedNode || null,
			});
			setVideoState((s) => ({ ...s, playing: true }));
			setSelectedOption(false);
			setEnded(false);
			if (rewindPlay) {
				setTimeout(() => {
					setVideoState((s) => ({ ...s, playing: false }));
					setShowAction(true);
					videoRef.current.seekTo(actionAfter / duration);
				}, 1000);
			}
		} else setVideoState((s) => ({ ...s, playing: false }));
	};

	const onEnded = () => {
		setEnded(true);
		playNextVideo();
	};

	const playPrevious = () => {
		let id = null;
		setPlayedNodes((s) => {
			const arr = [...s];
			id = arr.pop();
			return arr;
		});
		playNextVideo(id, true);
	};

	let tl = null;
	const onMouseMove = (cx) => {
		if (tl) clearTimeout(tl);
		gsap.to('.controls', {
			opacity: 1,
			duration: 1,
		});
		tl = setTimeout(() => {
			gsap.to('.controls', {
				opacity: 0,
				duration: 1,
			});
		}, 5000);
	};

	return (
		<div
			className="relative h-[100dvh]"
			id="player"
			onMouseMove={(e) => onMouseMove(e.clientX)}
		>
			<div
				className="h-[100dvh] w-full flex items-center justify-center"
				ref={playerRef}
			>
				<div className="relative">
					<ReactPlayer
						ref={videoRef}
						style={{
							background: '#000000',
							maxWidth: '100vw',
							maxHeight: '100vh',
						}}
						url={data.nodes[playingNodes.currentNode]?.src}
						{...videoState}
						width={heightWidth.w}
						height={heightWidth.h}
						onProgress={(s) => setProgress(s)}
						onEnded={onEnded}
					/>
					{showAction && !selectedOption && (
						<VideoActions
							pointer={
								data.nodes[playingNodes.currentNode].pointer
							}
							playNext={playNext}
							show={!selectedOption}
						/>
					)}
				</div>
			</div>
			<div className="flex opacity-0 flex-col w-full absolute bottom-0 left-0 controls">
				<div className="relative">
					<div className="w-full relative h-1 bg-white/30">
						<div
							className="h-full absolute bg-white/10 transition-all duration-1000"
							style={{ width: `${progress.loaded * 100}%` }}
						></div>
						<div
							className="h-full absolute bg-red-400 transition-all duration-1000"
							style={{ width: `${progress.played * 100}%` }}
						></div>
					</div>
					<div
						className="size-3 rounded-full bg-red-500 absolute -top-1 transition-all duration-1000"
						style={{ left: `${progress.played * 100}%` }}
					></div>
					<input
						type="range"
						min={0}
						max={100}
						defaultValue={progress.played}
						className="w-full h-1 absolute left-0 top-1/2 opacity-0 cursor-grab"
						onInput={(e) => {
							setProgress((s) => ({
								...s,
								played: e.target.value / 100,
							}));
							videoRef.current.seekTo(progress.played);
						}}
					/>
				</div>
				<div className=" bg-black/90 py-3 flex justify-between items-center flex-wrap">
					<div className="flex gap-3 px-4">
						<button
							onClick={() =>
								setVideoState((s) => {
									return { ...s, playing: !s.playing };
								})
							}
						>
							{videoState.playing ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={3.5}
									stroke="currentColor"
									className="w-7 h-7"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M15.75 5.25v13.5m-7.5-13.5v13.5"
									/>
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-7 h-7"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
									/>
								</svg>
							)}
						</button>
						<button
							onClick={() => {
								setVideoState((s) => ({
									...s,
									playing: false,
								}));
								videoRef.current?.seekTo(0);
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-7 h-7"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M5.25 7.5A2.25 2.25 0 0 1 7.5 5.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z"
								/>
							</svg>
						</button>
						<button
							onClick={() =>
								setVideoState((s) => ({
									...s,
									muted: !s.muted,
								}))
							}
						>
							{videoState.muted ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-7 h-7"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
									/>
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-7 h-7"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
									/>
								</svg>
							)}
						</button>
						<button
							onClick={playPrevious}
							className="disabled:text-white/60 ml-2 flex items-center gap-1 text-sm font-semibold"
							disabled={playedNodes.length === 0}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={2.5}
								stroke="currentColor"
								className="w-3 h-3"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M15.75 19.5 8.25 12l7.5-7.5"
								/>
							</svg>
							Previous choice
						</button>
					</div>
					<div className="px-4 text-[10px] flex gap-3 items-center ml-auto">
						<div>
							{Number.parseInt(duration)} s-{' '}
							{Number.parseInt(progress.playedSeconds)}s
						</div>
						<button onClick={() => requestFullScreen()}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-7 h-7"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div className="fullscreen-popup-container fixed top-0 left-0 right-0 bottom-0 bg-black/0 flex justify-center items-center ">
				<div className="fullscreen-popup scale-0 bg-slate-900 w-[80vw] p-5 pt-3 rounded-xl drop-shadow-xl font-semibold text-white/70">
					<div className='text-right'>
						<button onClick={closePopup}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 16 16"
								className='w-3 h-3'
							>
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
							</svg>
						</button>
					</div>
					<div className='text-center'>
						<div>For the best experience</div>
					</div>
					<div className="mt-5 flex justify-center text-white">
						<button className="flex items-center gap-2 bg-white/5 p-2 px-3 rounded-md hover:bg-white/10 text-sm" onClick={()=>{closePopup(); requestFullScreen(); setVideoState({...videoState, playing: true});}}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								className="w-4 h-4"
								viewBox="0 0 16 16"
							>
								<path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
							</svg>
							Go Fullscreen
						</button>
					</div>
					<div className="mt-4 text-right text-sm hover:text-white">
						<button className="" onClick={closePopup}>Never Mind</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Player;
