import { Link } from 'react-router-dom';
import banner from '../Assets/360Banner.mp4';
import iBanner from '../Assets/interactiveBanner.png';
import { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

const Universe = () => {
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, 0), 100);
		document.querySelector('title').innerText = 'Enteracty | 360 Universe';
		document.querySelector(
			'meta[name="description"]'
		).content = `Immerse yourself in the ultimate romantic adventure with "Some Like It Hot: A 360° virtual Date" Take control as you navigate through a 360° environment, exploring the charming ambiance of the some like it hot café from every angle. This experimental trial allows you to experience fully immersive and personalized choose your own adventure dating game and make this 360° journey uniquely yours. It's time to dive into the 360° Romantic Interactive  Date and make this virtual dating game uniquely yours with a twist of interactive film inside.`;
		document.body.style.overflowY = 'auto';
		try {
			document.querySelector('video').play();
		} catch (e) {
			console.log('Unable to play video');
		}
	}, []);

	const [activeTab, setActiveTab] = useState('related');
	const [expandText, setExpandText] = useState(false);

	return (
		<div>
			<Navbar />

			<div className="flex flex-col w-full lg:flex-row-reverse lg:items-end bg-linear-grad">
				<div className="relative">
					<a
						href={`whatsapp://send?text=${window.location.href}`}
						className="inline-block lg:hidden bg-black/30 p-2 absolute top-3 right-3 rounded-md cursor-pointer z-10"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-4 h-4"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
							/>
						</svg>
					</a>
					<video
						src={banner}
						className="min-h-[150px] min-w-full lg:min-w-[55vw]"
						muted={true}
						loop
					></video>
					<div className='hidden lg:block absolute -left-1 -right-1 -bottom-1 -top-1 bg-gradient-to-r from-black to-transparent'></div>
					<div
						className="absolute w-full bottom-0 left-0 font-bold text-3xl p-5 pt-12 pb-8 translate-y-10 lg:hidden"
						style={{
							background:
								'linear-gradient(0deg, black 35%, transparent 101%)',
						}}
					>
						Some Like It Hot: A 360° Virtual Date
					</div>
				</div>
				<div className="p-5 pt-12 lg:pt-5 lg:pl-10">
					<div
						className="font-bold text-3xl pb-3 hidden lg:block"
					>
						Some Like It Hot: A 360° Virtual Date
					</div>
					<a
						href="/360 Enteracty/index.htm"
						target="_blank"
						className="bg-white w-full lg:w-max px-10 text-black justify-center items-center flex h-12 font-semibold rounded-lg "
					>
						Play
					</a>
					<div className="mt-3">
						<span className={`text-white/80 ${!expandText ? 'line-clamp-6' : ''}`}>
							Immerse yourself in the ultimate romantic adventure
							with "Some Like It Hot: A 360° Virtual Date" Take
							control as you navigate through a 360° environment,
							exploring the charming ambiance of 'Some like it
							hot' café from every angle. This experimental trial
							allows you to experience fully immersive and
							personalized date and make this 360° journey
							uniquely yours. Will your decisions, from the
							setting till the end, lead to a memorable connection
							or an unexpected turn in this romantic journey? It's
							time to dive into the 360° Virtual Date and make
							this virtual date uniquely yours.
						</span>{' '}
						<button
							className="text-xs underline inline text-blue-600"
							onClick={() => setExpandText((s) => !s)}
						>
							see {expandText ? 'less' : 'more'}
						</button>
					</div>
					<div className="flex gap-2 py-2 flex-wrap text-white">
						<div className="p-1 px-2 rounded-full bg-white/10 text-sm">
							Romantic
						</div>
						<div className="p-1 px-2 rounded-full bg-white/10 text-sm">
							Comedy
						</div>
						<div className="p-1 px-2 rounded-full bg-white/10 text-sm">
							Some Like It Hot
						</div>
						<div className="p-1 px-2 rounded-full bg-white/10 text-sm">
							Experimental
						</div>
					</div>
				</div>
			</div>

			<div className="px-5 pb-5 lg:px-10">
				<div className="flex justify-center gap-3 my-5">
					<button
						className={
							activeTab === 'related'
								? 'underline underline-offset-4'
								: ''
						}
						onClick={(e) => setActiveTab('related')}
					>
						Related
					</button>
					<button
						className={
							activeTab === 'details'
								? 'underline underline-offset-4'
								: ''
						}
						onClick={(e) => setActiveTab('details')}
					>
						Details
					</button>
				</div>
				<div>
					{activeTab === 'related' ? (
						<>
							<div className="font-semibold text-sm mb-2">
								Also Watch
							</div>
							<div className="overflow-y-auto max-w-full">
								<div className="flex gap-3">
									<Link to="/interactive">
										<img
											src={iBanner}
											alt=""
											className="bg-white/10 rounded-xl w-60 aspect-[4/3] object-cover"
										/>
									</Link>
								</div>
							</div>
						</>
					) : (
						<div>
							<div className="font-semibold">Credits</div>
							<div className="text-sm flex flex-col gap-5 mt-4 text-white/70">
								<div>
									<div className="font-medium text-white">
										Starring:
									</div>
									Upasana Das
								</div>
								<div>
									<div className="font-medium text-white">
										Voice:
									</div>
									Upasana Das, Prantik Das, Rajasree Roy
								</div>
								<div>
									<div className="font-medium text-white">
										Sound edit:
									</div>
									Ayan Das
								</div>
								<div>
									<div className="font-medium text-white">
										DOP and Editing:
									</div>
									Dipti Karmakar
								</div>
								<div>
									<div className="font-medium text-white">
										Script:
									</div>
									Rajasree Roy
								</div>
								<div>
									<div className="font-medium text-white">
										360° Imaging and Editing:
									</div>
									Prantik Das
								</div>
								<div>
									<div className="font-medium text-white">
										Language:
									</div>
									English
								</div>
								<div>
									<div className="font-medium text-white">
										Genre:
									</div>
									Romantic comedy, Interactive, Experimental
								</div>
								<div>
									<div className="font-medium text-white">
										Maturity rating:
									</div>
									U/A
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Universe;
