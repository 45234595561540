import { Link, useNavigate } from 'react-router-dom';
import iBanner from '../Assets/interactiveBanner.png';
import uBanner from '../Assets/360thumb.jpg';
import { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

const Interactive = () => {
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, 0), 100);
		document.querySelector('title').innerText =
			'Enteracty | Interactive Cinema';
		document.querySelector('meta[name="description"]').content = ``;
		document.body.style.overflowY = 'auto';
	}, []);

	const [activeTab, setActiveTab] = useState('related');
	const [expandText, setExpandText] = useState(false);

	const navigate = useNavigate();
	return (
		<div>
			<Navbar />
			<div className="flex flex-col w-full lg:flex-row-reverse lg:items-end bg-linear-grad">
				<div className="relative">
					<a
						href={`whatsapp://send?text=${window.location.href}`}
						className="inline-block lg:hidden bg-black/30 p-2 absolute top-3 right-3 rounded-md cursor-pointer z-10"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-4 h-4"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
							/>
						</svg>
					</a>
					<img src={iBanner} className="min-h-[150px] lg:min-w-[55vw]" alt="" />
					<div className='hidden lg:block absolute -left-1 -right-1 -bottom-1 -top-1 bg-gradient-to-r from-black to-transparent'></div>
					<div
						className="absolute lg:hidden w-full bottom-0 left-0 font-bold text-3xl p-5 pt-12 pb-8 translate-y-10"
						style={{
							background:
								'linear-gradient(0deg, black 9%, transparent 91%)',
						}}
					>
						A Date with Her
					</div>
				</div>
				<div className="p-5 pt-12 lg:pt-5 lg:pl-10">
					<div className="font-bold text-3xl pb-3 hidden lg:block">
						A Date with Her
					</div>
					<button
						className="bg-white w-full lg:w-max px-10 text-black justify-center items-center flex h-12 font-semibold rounded-lg "
						onClick={() => navigate('/player')}
					>
						Play
					</button>
					<div className="mt-3">
						<span className={`text-white/80 ${!expandText ? 'line-clamp-6' : ''}`}>
							"A Date with Her" invites you to step into the shoes
							of the main character, where you become the guy on a
							charming date at the delightful Some Like It Hot
							Cafe. In this interactive film experienced from a
							first-person view, you have the power to shape the
							story by making decisions throughout the date.
							Engage choose and decide on mane, actions and
							conversations, that could either win her over or
							lead to an unexpected end. Your choices in this
							romantic journey directly impact the girl's
							feelings, influencing whether the date ends with a
							promise for more or a subtle farewell. It's your
							story, your decisions, and the fate of the date
							is in your hands.
						</span>{' '}
						<button
							className="text-xs underline text-blue-600"
							onClick={() => setExpandText((s) => !s)}
						>
							see {expandText ? 'less' : 'more'}
						</button>
					</div>
					<div className="flex gap-2 py-2 flex-wrap text-white">
						<div className="p-1 px-2 rounded-full bg-white/10 text-sm">
							Romantic
						</div>
						<div className="p-1 px-2 rounded-full bg-white/10 text-sm">
							Comedy
						</div>
						<div className="p-1 px-2 rounded-full bg-white/10 text-sm">
							Some Like It Hot
						</div>
					</div>
				</div>
			</div>
			<div className="p-5 pt-12 lg:px-10">
				<div className="flex justify-center gap-3 my-5">
					<button
						className={
							activeTab === 'related'
								? 'underline underline-offset-4'
								: ''
						}
						onClick={(e) => setActiveTab('related')}
					>
						Related
					</button>
					<button
						className={
							activeTab === 'details'
								? 'underline underline-offset-4'
								: ''
						}
						onClick={(e) => setActiveTab('details')}
					>
						Details
					</button>
				</div>
				<div>
					{activeTab === 'related' ? (
						<>
							<div className="font-semibold text-sm mb-2">
								Also Watch
							</div>
							<div className="overflow-y-auto max-w-full">
								<div className="flex gap-3">
									<Link to="/universe">
										<img
											src={uBanner}
											alt=""
											className="bg-white/10 rounded-xl w-60 aspect-[4/3] object-cover"
										/>
									</Link>
								</div>
							</div>
						</>
					) : (
						<div>
							<div className="font-semibold">Credits</div>
							<div className="text-sm flex flex-col gap-5 mt-4 text-white/70">
								<div>
									<div className="font-medium text-white">
										Starring:
									</div>
									Upasana Das
								</div>
								<div>
									<div className="font-medium text-white">
										Voice:
									</div>
									Upasana Das, Prantik Das, Rajasree Roy
								</div>
								<div>
									<div className="font-medium text-white">
										Sound edit:
									</div>
									Ayan Das
								</div>
								<div>
									<div className="font-medium text-white">
										DOP and Editing:
									</div>
									Dipti Karmakar
								</div>
								<div>
									<div className="font-medium text-white">
										Script:
									</div>
									Rajasree Roy
								</div>
								<div>
									<div className="font-medium text-white">
										Language:
									</div>
									English
								</div>
								<div>
									<div className="font-medium text-white">
										Genre:
									</div>
									Romantic comedy, Interactive
								</div>
								<div>
									<div className="font-medium text-white">
										Maturity rating:
									</div>
									U/A
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Interactive;
