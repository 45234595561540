import { BrowserRouter as Router, Routes, Route } from "react-router-dom";import Home from "./Pages/Home";
import Universe from "./Pages/Universe";
import Interactive from "./Pages/Interactive";
import Player from "./Pages/Player";
import About from "./Pages/About";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/universe" element={<Universe />} />
				<Route path="/interactive" element={<Interactive />} />
				<Route path="/player" element={<Player />} />
				<Route path="/about" element={<About />} />
			</Routes>
		</Router>
	)
}

export default App;
