export const data = {
    metadata: {
        projectName: 'First Date',
        aspectRatio: '16:9',
        startingNode: 'af559beb-cb14-482b-9c3a-e9a97511e89f',
    },
    nodes: {
        'af559beb-cb14-482b-9c3a-e9a97511e89f': {
            name: '1.mp4',
            src: 'https://firebasestorage.googleapis.com/v0/b/enteracty.appspot.com/o/New%20Videos%2F1Opening.mp4?alt=media&token=9bfff3c7-2f7b-4fc5-a7f4-112c2c48260b',
            pointer: {
                timeBefore: 7,
                context: 'Did you bring her Rose?',
                defaultFlow: 1,
                buttons: [
                    {
                        linkedNode: 'd15c1582-f904-4447-869f-c5b14b410b33',
                        style: {},
                        title: 'Yes',
                    },
                    {
                        linkedNode: '5dc9081e-1b4e-42e0-ab45-93cfe677790d',
                        style: {},
                        title: 'No',
                    },
                ],
            },
        },
        'd15c1582-f904-4447-869f-c5b14b410b33': {
            name: 'flower.mp4',
            src: 'https://firebasestorage.googleapis.com/v0/b/enteracty.appspot.com/o/New%20Videos%2F2Flower.mp4?alt=media&token=880d0f4f-893f-4985-938f-de33cc2c02ae',
            pointer: {
                timeBefore: 6,
                context: 'What you wanna have?',
                defaultFlow: 0,
                buttons: [
                    {
                        linkedNode: 'a1946225-810e-41d7-a366-511122ebdae1',
                        style: {},
                        title: 'Cappuchino',
                    },
                    {
                        linkedNode: '773d8440-f9e1-4ec7-8af7-8bc9d3852d7f',
                        style: {},
                        title: 'Espresso',
                    },
                ],
            },
        },
        '5dc9081e-1b4e-42e0-ab45-93cfe677790d': {
            name: 'noflower.mp4',
            src: 'https://firebasestorage.googleapis.com/v0/b/enteracty.appspot.com/o/New%20Videos%2F3No-Flower.mp4?alt=media&token=712165f9-925f-41f9-8feb-cdba39751df4',
            pointer: {
                timeBefore: 6,
                context: 'What you wanna have?',
                defaultFlow: 0,
                buttons: [
                    {
                        linkedNode: 'a1946225-810e-41d7-a366-511122ebdae1',
                        style: {},
                        title: 'Cappuchino',
                    },
                    {
                        linkedNode: '773d8440-f9e1-4ec7-8af7-8bc9d3852d7f',
                        style: {},
                        title: 'Espresso',
                    },
                ],
            },
        },
        'a1946225-810e-41d7-a366-511122ebdae1': {
            name: 'cappuchino.mp4',
            src: 'https://firebasestorage.googleapis.com/v0/b/enteracty.appspot.com/o/New%20Videos%2F4Cappuchino.mp4?alt=media&token=f7c24ecf-2b3f-4160-ac76-3205e3bafe72',
            pointer: {
                timeBefore: 2,
                context: 'Did you like it?',
                defaultFlow: -1,
                buttons: [
                    {
                        linkedNode: '3f7deff1-87c1-4b42-a1dd-fe63fd10c05f',
                        style: {},
                        title: 'Honest Review',
                    },
                    {
                        linkedNode: 'e758b0d5-b88e-47ec-8877-752094762fba',
                        style: {},
                        title: 'Indifferent Approval',
                    },
                ],
            },
        },
        '773d8440-f9e1-4ec7-8af7-8bc9d3852d7f': {
            name: 'espresso.mp4',
            src: 'https://firebasestorage.googleapis.com/v0/b/enteracty.appspot.com/o/New%20Videos%2F5Espresso.mp4?alt=media&token=9c9e1657-ad29-49af-8bde-e5162df2f180',
            pointer: {
                timeBefore: 2,
                context: 'Did you like it?',
                defaultFlow: -1,
                buttons: [
                    {
                        linkedNode: '3f7deff1-87c1-4b42-a1dd-fe63fd10c05f',
                        style: {},
                        title: 'Honest Review',
                    },
                    {
                        linkedNode: 'e758b0d5-b88e-47ec-8877-752094762fba',
                        style: {},
                        title: 'Indifferent Approval',
                    },
                ],
            },
        },
        '3f7deff1-87c1-4b42-a1dd-fe63fd10c05f': {
            name: 'critical.mp4',
            src: 'https://firebasestorage.googleapis.com/v0/b/enteracty.appspot.com/o/critical.mp4?alt=media&token=ca623624-bee6-4808-a929-69d43a1002e4',
            pointer: {
                timeBefore: 5,
                context: ' ',
                defaultFlow: -1,
                buttons: [
                    {
                        linkedNode: '5851f5d2-058c-450b-bae8-c8f85d0f6f2f',
                        style: {},
                        title: 'Pay the Bill',
                    },
                    {
                        linkedNode: 'f1857309-d348-4852-9861-68a09661d092',
                        style: {},
                        title: 'Split the Bill',
                    },
                ],
            },
        },
        'e758b0d5-b88e-47ec-8877-752094762fba': {
            name: 'fake.mp4',
            src: 'https://firebasestorage.googleapis.com/v0/b/enteracty.appspot.com/o/fake.mp4?alt=media&token=7e69d2f7-6aa4-497d-a26d-c21c920b0684',
            pointer: {
                timeBefore: 5,
                context: ' ',
                defaultFlow: -1,
                buttons: [
                    {
                        linkedNode: '5851f5d2-058c-450b-bae8-c8f85d0f6f2f',
                        style: {},
                        title: 'Pay the Bill',
                    },
                    {
                        linkedNode: 'f1857309-d348-4852-9861-68a09661d092',
                        style: {},
                        title: 'Split the Bill',
                    },
                ],
            },
        },
        '5851f5d2-058c-450b-bae8-c8f85d0f6f2f': {
            name: 'booh.mp4',
            src: 'https://firebasestorage.googleapis.com/v0/b/enteracty.appspot.com/o/New%20Videos%2F8Pay-the-bill.mp4?alt=media&token=5c439527-d84b-4094-87f3-3e373f08a05c',
            pointer: {
                timeBefore: 5,
                context: '',
                defaultFlow: 0,
                buttons: [],
            },
        },
        'f1857309-d348-4852-9861-68a09661d092': {
            name: 'yo.mp4',
            src: 'https://firebasestorage.googleapis.com/v0/b/enteracty.appspot.com/o/New%20Videos%2F9Split-the-bill.mp4?alt=media&token=e2a1d85e-c4bb-44d9-a5ff-cb5a09cd2c4f',
            pointer: {
                timeBefore: 5,
                context: '',
                defaultFlow: 0,
                buttons: [],
            },
        },
    },
};